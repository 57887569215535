import { useEffect, useMemo, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { TCPopup } from '../TCPopup/TCPopup';
import { useDispatch, useSelector } from 'react-redux';
import {
    swjOptedInTextSelector,
    swjOptedOutTextSelector,
    swjTiersSelector
} from '../../../../../selectors/site-wide-jackpot';
import { setJackpotWin } from '../../../../../redux/reducers/site-wide-jackpot/site-wide-jackpot';
import { useGameClientProxy } from '../../../../Launcher/GameClient/hooks/useGameClientProxy';
import { useJackpotFeed } from './helpers/useJackpotFeed';
import { providerSelector } from '../../../../../selectors/gameContext';
import { useInject } from 'inversify-hooks';
import { DI_SERVICE } from '../../../../../dependency-injection/constants';
import { setGameWindowExpansionDisabled } from '../../../../../redux/actions/game-window';
import { OptInButton } from '../OptInButton/OptInButton';
import { JackpotTier } from '../JackpotTier/JackpotTier';
import { createAnimation } from './create-animation';
import checkmark from '../../../../../assets/icons/SWJ/checkmark.svg';
import { useOptinManager } from './helpers/useOptinManager';
import { useSocialProofing } from './helpers/useSocialProofing';
import { linkJackpotsToWins } from './jackpotTiers';
import { useMDS } from './helpers/useMDS';

export const TopBar = ({ jackpotId, display }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    //For social proofing uncomment the code with currentMinorIndex
    const dispatch = useDispatch();
    const [getBalanceData] = useInject(DI_SERVICE.balanceData);
    const swjTiers = useSelector(swjTiersSelector);
    const optInOutText = {
        jackpotTextNotOptedIn: useSelector(swjOptedOutTextSelector),
        jackpotTextOptedIn: useSelector(swjOptedInTextSelector)
    };

    const isSpinInProgress = useSelector(state => state?.gameWindow?.spinInProgress);

    const gameProvider = useSelector(providerSelector);

    const { reloadBalance } = useGameClientProxy();

    const [jackpot, setJackpot] = useState(null);
    const [showTCPopup, setShowTCPopup] = useState(false);
    const forceTCPopup = useSelector(state => state?.customerData?.preferences?.showTCPopup);
    const tierRollTime = 10;

    const numberOfTiers = useMemo(() => jackpot && jackpot?.tiers?.length, [jackpot]);

    const { optedIn, handleOptInToggle } = useOptinManager(jackpotId);
    useJackpotFeed({ jackpot, jackpotId, optedIn, setJackpot });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    let { winnersFeed, winFrequency } = useSocialProofing(jackpotId); // these to be use for social proofing
    useMDS(jackpotId);

    useEffect(() => {
        optedIn !== 1 && setShowTCPopup(forceTCPopup || false)
    },[forceTCPopup])

    useEffect(() => {
        console.log({ isSpinInProgress, spinTimestamp: Date.now() });
        if (!isSpinInProgress) {
            if (gameProvider.toLowerCase() === "nyx" || gameProvider.toLowerCase() === "lnw") {
                dispatch(getBalanceData(false, (balance) => {
                    reloadBalance(balance);
                }));
            } else {
                reloadBalance();
            }

        }
    }, [isSpinInProgress]);

    useEffect(() => {
        if (dispatch) {
            dispatch(setGameWindowExpansionDisabled(showTCPopup));
        }
    }, [dispatch, showTCPopup])

    useEffect(() => {
        window.addEventListener('message', event => {
            if (event?.data?.type === 'GC/JACKPOT_WIN_EVENT') {
                const payload = JSON.parse(event.data.payload.payload);
                const tier = swjTiers[payload.jackpotTierId];
                dispatch(
                    setJackpotWin({
                        id: payload.jackpotTierId,
                        amount: payload.amount,
                        type: tier.shortName,
                        name: tier.name,
                        win_particles_animation: tier?.win_particles_animation?.url,
                        win_spin_animation: tier?.win_spin_animation?.url,
                    })
                );
            }
        });
    }, []);

    const handleShowPopup = (e) => {
        if (!isSpinInProgress) {
            e.stopPropagation();
            setShowTCPopup(prev => !prev);
        }
    };

    // force remount mechanism
    const [key, setKey] = useState(0);
    useEffect(() => {
        setKey((old) => old + 1);
    }, [optedIn, numberOfTiers]);

    return (
        <Fragment>
            {!!showTCPopup && display && (
                <TCPopup
                    toggleOptIn={handleOptInToggle}
                    userOptedIn={optedIn}
                    showTCPopup={showTCPopup}
                    handleShowPopup={handleShowPopup}
                />
            )}

            {display && jackpot && optedIn !== -2 && !!numberOfTiers &&
                <div
                    key={key}
                    className={`swj-top-bar-container \
                    ${optedIn ? 'opted-in' : ''} \
                    ${isSpinInProgress ? 'disabled' : 'enabled'} \
                `}>
                    <div className="swj-top-bar-mask">
                        {optedIn &&
                            <div className="swj-top-bar-checkmark-box">
                                <img className="swj-top-bar-checkmark" src={checkmark} alt="checkmark" />
                            </div>
                        }
                    </div>
                    <div
                        className={`swj-top-bar ${isSpinInProgress ? 'disabled' : 'enabled'}`}
                        onClick={handleShowPopup}
                    >
                        {numberOfTiers && <style>{createAnimation(numberOfTiers)}</style>}
                        <div className="jackpot-tiers-container" style={{
                            animation: `slide-up${numberOfTiers}`,
                            animationDuration: `${tierRollTime * numberOfTiers}s`,
                            animationIterationCount: 'infinite',
                            animationTimingFunction: 'linear, step-end'
                        }}>
                            {[...jackpot?.tiers, jackpot?.tiers?.[0]]?.map?.((jackpotTier, index) => <JackpotTier
                                key={index}
                                jackpotTier={linkJackpotsToWins(jackpotTier, winFrequency)}
                                tierLogo={swjTiers[jackpotTier?.id]?.logo}
                                userOptedIn={optedIn}
                                SPServiceActive={winFrequency?.[0]?.tiersNumberOfWins?.[0] ?? false}
                            />)}
                        </div>

                        <div className="swj-message">
                            {optedIn
                                ? optInOutText?.jackpotTextOptedIn
                                : optInOutText?.jackpotTextNotOptedIn}
                        </div>

                        <OptInButton
                            isSpinInProgress={isSpinInProgress}
                            userOptedIn={optedIn}
                            showTCPopup={showTCPopup}
                            handleShowPopup={handleShowPopup}
                        />
                    </div>
                </div>
            }
        </Fragment>
    );
};
