export const createAnimation = (numberOfTiers) => {
    if (!numberOfTiers) {
        return '';
    }

    let anim = `@keyframes slide-up${numberOfTiers} {`
    // percentage of transition between tiers
    const delayPercentage = 2;
    // percentage of each tier shown
    const showPercentage = (100 - (numberOfTiers * delayPercentage)) / numberOfTiers;

    // number of tiers + 1 because first tier is repeated at the end
    // first tier and "last" tier have half shown duration each
    for (let index = 0; index < numberOfTiers + 1; index++) {
        if (index === 0) {
            anim += `
                0%,
                ${(delayPercentage + showPercentage) / 2}% {
                    transform: translateY(0%);
                }`;
        } else if (index === numberOfTiers) {
            // subtract delay because it was added already at the beginning of the animation
            anim += `
                ${100 - (showPercentage - delayPercentage) / 2}%,
                100% {
                    transform: translateY(-${numberOfTiers * 54}px);
                }`;
        } else {
            // add delay for each shown item after the first one
            // start: 1, 3, 5, 7... * (show + delay) / 2 + delay
            //   end: 3, 5, 7, 9... * (show + delay) / 2 -> delay is already inserted at start
            anim += `
                ${((2 * index - 1) * (showPercentage + delayPercentage)) / 2 + delayPercentage}%,
                ${((2 * index + 1) * (showPercentage + delayPercentage)) / 2}% {
                    transform: translateY(-${index * 54}px);
                }`;
        }
    }
    anim += '}';
    return anim;
}
