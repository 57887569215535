import { JackpotFeed } from '@fanduel/casino-swj-consumer';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_TYPE } from '../../../../../../game-window/contants/errors';
import { setErrorMessageDisplayed, setInGameError } from '../../../../../../redux/actions/game-window';
import { getFgpEndpoint } from '../../../../../../common/helpers/get-fgp-endpoint';
import { swjProviderSelector, swjTiersSelector } from '../../../../../../selectors/site-wide-jackpot';

export const useJackpotFeed = ({ jackpot, jackpotId, optedIn, setJackpot }) => {
    const dispatch = useDispatch();

    const userId = useSelector(state => state?.['session']?.userId);
    const gameLoaded = useSelector(state => state?.['gameWindow']?.gameLoaded);
    const swjProvider = useSelector(swjProviderSelector);
    const swjTiers = useSelector(swjTiersSelector);
    const tiersIds = Object.keys(swjTiers);

    const [swjErrors, setSwjErrors] = useState([]);
    const JackpotFeedInstance = useMemo(() => new JackpotFeed(), []);

    const handleJackpotUpdate = jackpots => {
        if (!jackpots || !jackpots.length) {
            return;
        }

        const fetchedJackpot = Object.assign(jackpots.find(j => j.id === jackpotId));

        if (!fetchedJackpot) {
            return;
        }

        if (swjErrors.length > 0) {
            const tickerErrorIndex = swjErrors.findIndex(e => e.errorType === ERROR_TYPE.SWJ_SERVICE_ERROR);
            setSwjErrors(prev => {
                const newList = [...prev];
                newList.splice(tickerErrorIndex, 1);

                return newList;
            })
        }

        fetchedJackpot.tiers = fetchedJackpot.tiers
            .filter(tier => tiersIds.includes(tier.id))
            .sort((a, b) => (a.currentAmount < b.currentAmount ? -1 : 1));

        fetchedJackpot.tiers = fetchedJackpot.tiers.map(tier => {
            return {
                ...tier,
                name: swjTiers[tier.id]?.shortName
            };
        });

        if (!jackpot) {
            setJackpot(fetchedJackpot);
        } else {
            setJackpot(prev => {
                return { ...prev, tiers: [...fetchedJackpot.tiers] };
            });
        }
    };

    useEffect(() => {
        if (swjErrors.length > 0 && optedIn) {
            dispatch(setErrorMessageDisplayed(true));
            dispatch(setInGameError(swjErrors[0]));
        }
    }, [dispatch, gameLoaded, swjErrors, optedIn]);

    useEffect(() => {
        if (!userId || !swjProvider) {
            return;
        }

        JackpotFeedInstance.init({
            host: getFgpEndpoint(),
            provider: swjProvider,
            successCallback: handleJackpotUpdate,
            errorCallback: e => {
                setSwjErrors(prev => [
                    ...prev,
                    {
                        errorCode: ERROR_TYPE.SWJ_SERVICE_ERROR,
                        errorMessage: e.message,
                        errorType: ERROR_TYPE.SWJ_SERVICE_ERROR
                    }
                ]);
            },
            persistValue: true,
            timeout: 5000
        });
    }, [userId, swjProvider]);

    useEffect(() => {
        JackpotFeedInstance.resetSuccessCallback(handleJackpotUpdate);
    }, [swjErrors]);
}
