type JackpotTier = {
    id: string,
    type: string,
    currency: 'USD' | 'CAD',
    prevAmount: number,
    currentAmount: number,
    lastWonTime: number,
    name: 'Mini' | 'Minor' | 'Major' | 'Mega'
};
type CompleteJackpotTier = JackpotTier & { numberOfWins: number };

type TiersNumberOfWins = {
    tierRef: string,
    numberOfWins: number
};
type WinFrequency = {
    jackpotRef: string,
    tiersNumberOfWins: Array<TiersNumberOfWins>
};

export const linkJackpotsToWins = (jackpotTier: JackpotTier, winFrequencies: Array<WinFrequency>): CompleteJackpotTier => {
    return {
        ...jackpotTier,
        numberOfWins: ((Date.now() - jackpotTier?.lastWonTime) < 24 * 3600 * 1000) &&
            ((winFrequencies?.[0]?.tiersNumberOfWins?.find?.((win) => win?.tierRef === jackpotTier?.id))?.numberOfWins || 0) || 0
    }
}
