import axios, { AxiosInstance } from "axios";

import { getTokens } from "./helpers";
import { composeAcknowledgementMessage, composeGetUndeliveredMessages, composeSubscriptionMessage, } from "./messages";
import { JackpotWinMockObject } from "./types";

let socket: WebSocket;
let httpClient: AxiosInstance;
let force = false;
let connectionTimeoutMs = 30000;

const MDS = (
  host: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageCallback?: (data: any) => void,
) => {
  const tokens = getTokens();
  const wsConfig = {
    host,
    Authorization: `X-Auth-Token=${tokens.authToken};X-Login-Token=${tokens.loginToken}`,
  };

  const header = window.btoa(JSON.stringify(wsConfig));

  const wsEndpoint = `wss://${host.replace("appsync-api", "appsync-realtime-api")}/graphql?header=${header}&payload=e30=`;

  let interval: ReturnType<typeof setInterval>;

  const reviveConnection = (userId: string) => {
    force = true;
    closeConnection();
    initSocket(userId);
  };

  const timeoutTimer = (userId: string) => {
    interval && clearInterval(interval);
    interval = setInterval(() => {
      reviveConnection(userId);
    }, connectionTimeoutMs);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const acknowledge = async (payload: any) => {
    console.log("acknowledge method called with payload: ", { payload });

    if (!payload?.data?.addedMessage) {
      return;
    }

    const toSend = {
      userId: payload.data.addedMessage.userId,
      messageId: payload.data.addedMessage.messageId,
      createdTs: payload.data.addedMessage.createdTs,
    }
    const { authToken, loginToken } = getTokens();

    return httpClient.post("/graphql", composeAcknowledgementMessage(toSend), {
      headers: {
        AWS_LAMBDA: `X-Auth-Token:${authToken};X-Login-Token:${loginToken}`,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDataReceived = (data: any) => {
    console.log("WS Data Received: ", data);
    messageCallback && messageCallback(data);
  };

  httpClient = axios.create({
    baseURL: `https://${host}`,
    headers: wsConfig,
  });

  const getUndeliveredMessages = async (userId: string) => {
    const { authToken, loginToken } = getTokens();
    return httpClient.post("/graphql", composeGetUndeliveredMessages(userId), {
      headers: {
        AWS_LAMBDA: `X-Auth-Token=${authToken};X-Login-Token=${loginToken}`,
      },
    });
  };

  const openSocket = () => {
    try{
      socket = new WebSocket(wsEndpoint, "graphql-ws") as WebSocket;
    } catch (error) {
      messageCallback && messageCallback({ data: { addedMessage: { type: "ERROR", payload: JSON.stringify({
        message: "Initialization Error",
        errorCode: "SWJ-001"
      }) } } });
      messageCallback && messageCallback({ 
        data: { 
          addedMessage: { 
            type: "ERROR", 
            payload: JSON.stringify({
              message: "Initialize Error",
              errorCode: "SWJ-001"
            })
          }
        }
      });
    }
  }

  const initSocket = (userId: string) => {
    if (force || !socket) {
      openSocket();

      socket.addEventListener("open", (event) => {
        console.log("WS Open", event);
        const message = JSON.stringify({
          type: "connection_init",
        });
        socket.send(message);
        force = false;
      });

      socket.addEventListener("close", (event) => {
        messageCallback && messageCallback({ 
          data: { 
            addedMessage: { 
              type: "ERROR", 
              payload: JSON.stringify({
                message: !force ? "Initialize Error" : "Keep Alive Error",
                errorCode: !force ? "SWJ-201" : "SWJ-002"
              })
            }
          }
        });
        console.log("WS Closed", event);
      });

      socket.addEventListener("message", (event) => {
        console.log("Message from websocket server", event.data);

        try {
          const msg = JSON.parse(event.data);
          switch (msg.type) {
          case "connection_ack":
            console.log(
              "socket connection intialized. Can start sending messages",
            );
            socket.send(composeSubscriptionMessage(userId, host));
            connectionTimeoutMs = msg.payload.connectionTimeoutMs
            break;
          case "data":
            onDataReceived(msg.payload);
            acknowledge(msg.payload);
            break;
          case "ka":
            console.log("WS KA Received");
            timeoutTimer(userId);
            break;
          default:
            console.log("unprocessable socket message");
          }
        } catch (error) {
          console.error("error parsing socket message: ", error);
        }
      });

      socket.addEventListener("error", (error) => {
        messageCallback && messageCallback({ 
          data: { 
            addedMessage: { 
              type: "ERROR", 
              payload: JSON.stringify({
                message: !force ? "Initialize Error" : "Keep Alive Error",
                errorCode: !force ? "SWJ-201" : "SWJ-002"
              })
            }
          }
        });
        console.log("Error from websocket server", error);
      });
    } else {
      console.log("socket already opened");
    }

    return socket;
  };

  const closeConnection = () => {
    socket.close();
  };

  const emitMockJackpotWin = (input: JackpotWinMockObject) => {
    messageCallback?.(input);
    window.postMessage({ type: "GC/JACKPOT_WIN_EVENT", payload: input }, "*");
  };

  return {
    initSocket,
    closeConnection,
    getUndeliveredMessages,
    emitMockJackpotWin,
  };
};

export default MDS;
