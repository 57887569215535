import { useEffect, useState } from 'preact/hooks';
import { WinFrequency, WinnersFeed } from '@fanduel/casino-swj-consumer';
import { getFgpEndpoint } from '../../../../../../common/helpers/get-fgp-endpoint';
import { getRegion } from '../../../../../../common/helpers/cookies';

export const useSocialProofing = (jackpotId) => {
    const [winnersFeed, setWinnersFeed] = useState(null);
    const [winFrequency, setWinFrequency] = useState(null);

    const winnersFeedInstance = new WinnersFeed({
        host: getFgpEndpoint(),
        region: getRegion(),
        jackpotIds: [jackpotId],
        numberOfUsers: 10,
        callback: setWinnersFeed, // response contains data[0].['latestWins'], the message should be crafted from that information
        error: error => {
            // you can use this with a callback in the parent to disable displaying the winners feed
            console.error(error);
        }
    })

    const winFrequencyInstance = new WinFrequency({
        host: getFgpEndpoint(),
        region: getRegion(),
        timeInterval: 'last24h',
        jackpotIds: [jackpotId],
        callback: setWinFrequency, // response contains data[0].['tiersNumberOfWins'], the message should be matched from that information
        error: (e) => {
            // you can use this with a callback in the parent to disable displaying the win frequency
            console.log(e);
        }
    });

    useEffect(() => {
        winnersFeedInstance.connect();
        winFrequencyInstance.connect();

        return () => {
            winnersFeed.disconnect();
            winFrequencyInstance.disconnect();
        };
    }, [])

    return { winnersFeed, winFrequency };
}
